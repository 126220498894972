import { gql } from '@apollo/client';

export const CREATE_DELIVERY_FEE_MUTATION = gql`
  mutation createDeliveryRule($shippingFrom: String!, $shippingTo: String!, $deleiveryFee: Decimal!, $quantityRange: String!) {
    createDeliveryRule(shipTo: $shippingTo shipFrom: $shippingFrom, fee: $deleiveryFee, quantityRange: $quantityRange) {
      deliveryRule {
        id
        shipFrom
        shipTo
        fee
        isActive
      }
    }
  }
`;

export const UPDATE_DELIVERY_FEE_MUTATION = gql`
  mutation updateDeliveryRule($id: ID!, $deleiveryFee: Decimal!, $shippingFrom: String!, $shippingTo: String!, $quantityRange: String!) {
    updateDeliveryRule(id: $id, fee: $deleiveryFee, shipTo: $shippingTo shipFrom: $shippingFrom, quantityRange: $quantityRange) {
      message
    }
  }
`;

export const CHANGE_DELIVERY_FEE_MUTATION = gql`
  mutation changeDeliveryRuleStatus($id: ID!, $status: Boolean!) {
    changeDeliveryRuleStatus(id: $id, status: $status) {
      message
    }
  }
`;

export const DELETE_DELIVERY_FEE_MUTATION = gql`
  mutation deleteDeliveryRule($id: ID!) {
    deleteDeliveryRule(id: $id) {
      message
    }
  }
`;
