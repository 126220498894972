import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, UnderlineGrid, Typo, NoteBox,
  NoteTypo, NoteImage, SubNoteTypo
} from './tabsButtons.styles';
import NoteIcon from '../../../../../assets/images/noteicon.png';

const TabsButtons = ({ active, changePackage, packages }) => (
  <>
    <StatusesGrid>
      {packages.map((pkg, index) => (
        <UnderlineGrid
          key={pkg}
          onClick={() => changePackage(index)}
          active={active === index}
        >
          <Typo active={active === index}>{pkg}</Typo>
        </UnderlineGrid>
      ))}
      <UnderlineGrid marginTop="1.3rem">
        <Typo>{' '}</Typo>
      </UnderlineGrid>
    </StatusesGrid>

    <NoteBox>
      <NoteImage src={NoteIcon} alt="icon" />
      <NoteTypo>Quick Note</NoteTypo>
      <SubNoteTypo>Your order will be shipped in multiple packages, each coming directly from its respective seller.</SubNoteTypo>
    </NoteBox>
  </>
);
TabsButtons.propTypes = {
  active: PropTypes.number.isRequired,
  changePackage: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TabsButtons;
