import { gql } from '@apollo/client';

export const GET_ALL_DELIVERY_RULES_QUERY = gql`
  query deliveryRules($pageNumber: Int, $pageCount: Int) {
    deliveryRules(pageNumber: $pageNumber, pageCount: $pageCount) {
      id
      shipFrom
      shipTo
      quantityMin
      quantityMax
      fee
      isActive
    }
    deliveryRulesTotalNumber
  }
`;
