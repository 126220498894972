import {
  Grid, Typography, Paper, Button, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ShippingFeeGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
  background-color:  #F7FBFF;
  min-height: 80vh;
  @media(max-width: 991px) {
    padding: 0px 30px;
    background-color: #fff;
  }
`;

export const ShippingFeeWrapper = styled(Grid)`
  background: #FFFFFF;
  box-shadow: 0px 11px 18px 0px #0000000A;
  border-radius: 12px;
  padding: 20px 30px 5rem;
  margin-top: 1rem;
  @media(max-width: 991px) {
    background: none;
  }
`;

export const TitleGridContainer = styled(Grid)`
  padding: 1rem 3rem 1rem .7rem;
  align-items: center;
  justify-content: space-between;
`;

export const AddFee = styled(Button)`
  width: 11.125rem;
  height: 45px;
  background: #235A91;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  font-weight: 700;
  font-size: .875rem;
`;

export const ButtonsPaper = styled(Paper)`
  border-bottom: 3px solid #EEEEEE;
  width: 100%;
`;

// export const TitleTextGridContainer = styled(Grid)`
//   align-items: center;
//   display: flex;
// `;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  line-height: 29px;
  color: #303030;


  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const SubTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.99px;
  color: #606060;
  margin-top: 10px;



  @media(min-width: 992px) {
    font-size: 0.9rem;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const Description = styled(Typography)`
  font-size: .875rem;
  color: #606060;
  font-weight: 400;
  padding-top: 1rem;
`;

export const Title = styled(Typography)`
  font-size: 1.125rem;
  color: #303030;
  font-weight: 700;
`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
    padding: .8rem;
    max-height: 31.438rem;
    width: 28.813rem;
    box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  }
`;

export const CancelButton = styled(Button)`
  border-radius: 5px;
  text-align: center;
  font-size: .875rem;
  font-weight: 700;
  border: 1.5px solid #235a91;
  width: 11.125rem;
  height: 3rem;
  color: #235a91;
`;

export const RemoveButton = styled(Button)`
  border-radius: 5px;
  text-align: center;
  font-size: .875rem;
  font-weight: 700;
  background: #235a91;
  width: 11.125rem;
  height: 3rem;
  color: #ffffff;

  &:hover {
    background: #235a91;
    color: #ffffff;
  }
`;
