import React, { useLayoutEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import { GET_ALL_DELIVERY_RULES_QUERY } from '../../../queries/admin';
import { useStateValue } from '../../../providers/stateProvider';
import ShippingFee from './shippingFee/shippingFee';
import MainContent from '../../customComponents/mainContent';
import {
  AddFee, TitleGridContainer, PrimaryTitle, TextWrapper, TitleTextGridContainer,
  ShippingFeeGridContainer, ShippingFeeWrapper
} from './shippingFeeContainer.styles';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';
import CreateFeeDialog from './CreateFeeDialog/createFeeDialog';


const ShippingFeeAdminContainer = () => {
  const [, dispatch] = Object.values(useStateValue());
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [createFeeDialog, setCreateFeeDialog] = useState(false);
  const [editFeeDialog, setEditFeeDialog] = useState(false);
  const [shippingId, setShippingId] = useState(null);


  useLayoutEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, [dispatch]);

  const handleDialog = () => {
    setCreateFeeDialog(!createFeeDialog);
  };

  // Function to handle shipping rowData sent from ShippingFee
  const handleShippingId = (rowData) => {
    setShippingId(rowData);
    setEditFeeDialog(true);
  };

  const variables = {
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
  };
  const {
    data, loading, error, refetch
  } = useQuery(GET_ALL_DELIVERY_RULES_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
  });

  if (error) return `Error! ${error.message}`;

  return (
    <MainContent>
      <>
        <ShippingFeeGridContainer>
          <TitleGridContainer container item xs={12} justifyContent="space-between">
            <TitleTextGridContainer item md={5}>
              <PrimaryTitle variant="h5">Admin</PrimaryTitle>
            </TitleTextGridContainer>
            <Grid container md={7} spacing={2} justifyContent="flex-end">
              <AddFee
                data-testid="export"
                item
                onClick={() => setCreateFeeDialog(true)}
                className="mpAdmin-uat-admin-add-shipping-fee"
              >
                <TextWrapper>
                  Add Fee
                </TextWrapper>
              </AddFee>
            </Grid>
          </TitleGridContainer>
          <ShippingFeeWrapper>
            <ShippingFee
              data={data}
              error={error}
              loading={loading}
              refetch={refetch}
              pageCount={+pageCount}
              pageNumber={+pageNumber}
              setPageCount={setPageCount}
              setPageNumber={setPageNumber}
              setCreateFeeDialog={setCreateFeeDialog}
              handleShippingId={handleShippingId}
            />
          </ShippingFeeWrapper>
        </ShippingFeeGridContainer>
        <CreateFeeDialog
          open={createFeeDialog}
          close={handleDialog}
          isEdit={editFeeDialog}
          setEditFeeDialog={setEditFeeDialog}
          shippingId={shippingId}
          refetch={refetch}
          setShippingId={setShippingId}
        />
      </>
    </MainContent>
  );
};

export default ShippingFeeAdminContainer;
