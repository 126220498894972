import * as React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo, FilledButton, FilledButtonText
} from './actionPopper.style';
import { handleDownloadUtil, RenderPopper } from '../../../../../utils/utils';
import { OgaToken } from '../../../../../graphql/token';

export default function ActionButtonsPopper({ state }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen(!open);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const token = localStorage.getItem('oga_rest_token');

  const handleGenerateProforma = async (type) => {
    try {
      const { orderId, userId } = state;
      await handleDownloadUtil(OgaToken.SERVER_LINK, `download-proforma-xlsx/${orderId}?user_id=${+userId}&proforma_type='${type}'`, token, orderId);
    } catch (err) {
      toast.error('Proforma download failed');
      console.error('Invoice download failed:', err);
    }
  };

  return (
    <>
      <FilledButton
        onClick={handleToggle}
        ref={anchorRef}
      >
        <FilledButtonText>Generate Proforma</FilledButtonText>
      </FilledButton>
      <RenderPopper
        open={open}
        anchorEl={anchorRef.current}
        popperPlacement="bottom"
        onClickAway={handleClose}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
      >
        <GridWrapper container item>
          <GridContainer
            container
            item
            className="erp-uat-create-individually"
            onClick={() => { handleGenerateProforma('single'); setOpen(false); }}
            data-testid="erp-uat-create-individually"
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Single Proforma
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
          <GridContainer
            container
            item
            className="erp-uat-create-automatic-order"
            onClick={() => { handleGenerateProforma('combined'); setOpen(false); }}
            data-testid="erp-uat-create-automatic-order"
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Combined Proforma
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </RenderPopper>
    </>
  );
}

ActionButtonsPopper.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired
};

ActionButtonsPopper.defaultProps = {
};
