import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import currencyFormatter from '../currencyFormatter';
import {
  CardHeaderGrid, CardHeaderText, GrandTotalGrid, CardGridText,
  CardGridSubText, TipTool,
  TotalText
} from './paymentSummary.styles';
import ServiceFeePopper from '../../cart/paymentSummaryCard/serviceFeePopper';

const PaymentSummary = ({
  orderTotal, serviceFee, deliveryFee
}) => {
  const [action, setAction] = useState(null);
  const ordTotal = orderTotal - deliveryFee - serviceFee;

  const handleOpenTip = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  return (
    <>
      <CardHeaderGrid item container>
        <CardHeaderText>Payment Summary</CardHeaderText>
      </CardHeaderGrid>
      <Grid item container>
        <CardGridText item xs={8}>Order Total</CardGridText>
        <CardGridSubText item xs={4}>
          {`₦ ${currencyFormatter(ordTotal)}`}
        </CardGridSubText>
        <CardGridText item xs={8}>Delivery Fee</CardGridText>
        <CardGridSubText item xs={4}>
          {`₦ ${currencyFormatter(deliveryFee || 0)}`}
        </CardGridSubText>
        <CardGridText item xs={8}>
          Service Fee
          <TipTool onMouseLeave={(event) => handleOpenTip(event)} onMouseEnter={(event) => handleOpenTip(event)} />
        </CardGridText>
        <CardGridSubText item xs={4}>
          {`₦ ${currencyFormatter(serviceFee)}`}
        </CardGridSubText>
      </Grid>
      <GrandTotalGrid item container>
        <TotalText item xs={8}>
          Grand Total
        </TotalText>
        <CardGridSubText item xs={4}>
          {`₦ ${currencyFormatter(orderTotal)}`}
        </CardGridSubText>
      </GrandTotalGrid>
      <ServiceFeePopper action={action} handleOpenTip={handleOpenTip} />
    </>
  );
};

PaymentSummary.propTypes = {
  deliveryFee: PropTypes.number,
  serviceFee: PropTypes.number,
  orderTotal: PropTypes.number,
};

PaymentSummary.defaultProps = {
  serviceFee: 0,
  orderTotal: 0,
  deliveryFee: 0
};

export default PaymentSummary;
