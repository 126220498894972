import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { CTextField } from './styles';
import { addHyphen } from '../methods';

const CustomNewTextField = ({
  state, field, handleChange, select, maxHeight, marginLeft, productFilter, ...props
}) => {
  const returnType = (name) => {
    if (select) return;
    switch (name) {
      case 'dateFrom':
      case 'dateTo':
        return 'date';
      case 'orderTotalFrom':
      case 'orderTotalTo':
      case 'orderNo':
      case 'invoiceNo':
        return 'number';
      default:
        return 'text';
    }
  };

  const isLarge = useMediaQuery('(min-width:992px)');

  const {
    name: fieldName, label, shrink
  } = field;
  const _value = state[fieldName];
  return (
    <CTextField
      {...props}
      variant="outlined"
      {...(label ? { label } : {})}
      value={_value || ''}
      size={isLarge ? 'small' : 'medium'}
      type={returnType(fieldName)}
      name={fieldName}
      onChange={handleChange}
      select={select}
      straight
      inputProps={{ 'data-testid': fieldName }}
      InputLabelProps={shrink && { shrink: true }}
      className={`uat-filters-${addHyphen(fieldName)}`}
      SelectProps={select && {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              width: _value === 'ACTIVE' || _value === 'IN_ACTIVE'
                ? '10rem'
                : productFilter
                  ? '17.813rem'
                  : '16rem', minHeight: '6rem', borderRadius: '.5rem',
              boxShadow: '0px 4px 11px rgba(179, 179, 179, 0.43)', marginLeft, maxHeight
            }
          },
        }
      }}
    />
  );
};

CustomNewTextField.propTypes = {
  state: PropTypes.instanceOf(Object),
  field: PropTypes.string,
  select: PropTypes.bool,
  straight: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  maxHeight: PropTypes.string,
  marginLeft: PropTypes.string,
  productFilter: PropTypes.bool
};

CustomNewTextField.defaultProps = {
  state: {},
  field: '',
  select: false,
  straight: false,
  maxHeight: '',
  marginLeft: '',
  productFilter: false
};

export default CustomNewTextField;
