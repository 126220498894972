import React from 'react';
import {
  useMediaQuery,
  DialogContent,
  Slide,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import exclamation from '../../assets/images/Warning.gif';

import {
  DialogContentStyled,
  DescStyled,
  ButtonDarkStyled,
  FooterStyled,
  DialogContainer
} from './successDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmUnavailable = ({
  openDialog, setOpenDialog
}) => {
  const handleClose = async () => {
    setOpenDialog(false);
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute', right: '1.5rem', cursor: 'pointer', fontSize: isSmall ? '3rem' : ''
          }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={exclamation} alt="warning" width={isSmall ? 350 : 170} />
            </Box>
            <DescStyled>
              Unfortunately, one or more items in your order are currently unavailable.
              Please review your cart and remove all unavailable product highlighted in
              <b> grey</b>
              .
            </DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <ButtonDarkStyled
            onClick={handleClose}
            fullWidth
          >
            Proceed
          </ButtonDarkStyled>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

ConfirmUnavailable.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};

ConfirmUnavailable.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
};

export default ConfirmUnavailable;
