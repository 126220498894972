import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './tabsButtons.styles';

const TabsButtons = ({ active, changePackage, packages }) => (
  <StatusesGrid>
    {packages.map((pkg, index) => (
      <UnderlineGrid
        key={pkg}
        onClick={() => changePackage(index)}
        active={active === index}
      >
        <Typo active={active === index}>{pkg}</Typo>
      </UnderlineGrid>
    ))}
    <UnderlineGrid marginTop="1.3rem">
      <Typo>{' '}</Typo>
    </UnderlineGrid>
  </StatusesGrid>
);
TabsButtons.propTypes = {
  active: PropTypes.number.isRequired,
  changePackage: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TabsButtons;
