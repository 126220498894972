import React from 'react';
import {
  Button, DialogActions, DialogContent, DialogContentText,
  Slide, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../../assets/images/Checksuccesful.gif';
import warning from '../../../assets/images/Warning.gif';
import {
  Description, Title, CDialog, CancelButton, RemoveButton
} from './shippingFeeContainer.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openConfirmation,
  handleClose,
  handleConfirm,
  loading,
  title,
  discreption,
  buttonTyle,
  imgType,
  buttonTitle
}) => (
  <CDialog
    open={openConfirmation}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="large"
        style={{
          position: 'absolute', right: '1.5rem', cursor: 'pointer', fontSize: '1.5rem'
        }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            {imgType === 'succes' ? (
              <img src={success} alt="success" width={155} />
            ) : (
              <img src={warning} alt="exclamation" width={120} />
            )}
          </Box>
          <Title>
            {title}
          </Title>
          <Description>
            {discreption}
          </Description>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '1rem', gap: '1rem' }}>
        {buttonTyle === 'single' ? (
          <Button
            fullWidth
            onClick={handleClose}
            variant="contained"
            style={{
              background: '#235A91',
              color: '#FFF',
              padding: '1rem 2rem'
            }}
            size="large"
          >
            ok
          </Button>
        ) : (
          <>
            <CancelButton onClick={handleClose}>
              Cancel
            </CancelButton>
            {loading ? (
              <RemoveButton disabled>
                <Box display="flex" alignItems="center">
                  <span>Loading...</span>
                </Box>
              </RemoveButton>
            ) : (
              <RemoveButton onClick={() => handleConfirm()}>
                {buttonTitle || '' }
              </RemoveButton>
            )}
          </>
        )}
      </DialogActions>
    </DialogContent>
  </CDialog>
);

ConfirmDialog.propTypes = {
  openConfirmation: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  discreption: PropTypes.string.isRequired,
  buttonTyle: PropTypes.string,
  imgType: PropTypes.string,
  buttonTitle: PropTypes.string
};

ConfirmDialog.defaultProps = {
  buttonTitle: '',
  buttonTyle: '',
  imgType: '',
  loading: false
};

export default ConfirmDialog;
