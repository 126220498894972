import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, Table, Box
} from '@mui/material';
import {
  TableContainerWrapper, TableComponent, Header, FooterGrid,
  MainTableHead, TableGrid
} from './shippingFee.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../../shared/tablePagination';

const headers = [
  { name: 'Shipping (From)' },
  { name: 'Shipping (To)' },
  { name: 'Quantity Count' },
  { name: 'Delivery Fee' },
  { name: 'Status' },
  { name: 'Actions', }
];

const ShippingFee = ({
  data, loading, error, refetch,
  pageCount, pageNumber, setPageCount, setPageNumber, setCreateFeeDialog, handleShippingId
}) => {
  const returnHeaders = () => headers.map((header) => {
    const { name } = header;
    return (
      <Header key={name}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {name}
        </Box>
      </Header>
    );
  });

  const adminDeliveryRules = data?.deliveryRules || [];
  const totalCount = data?.deliveryRulesTotalNumber || 0;

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <TableContainerWrapper>
        <TableGrid item container>
          {loading ? <ProductsTableLoader /> : (
            <TableComponent item container aria-label="Orders table">
              <Table style={{ width: '100%' }} data-testid="mp-uat-shippingFee">
                <MainTableHead item container>
                  {returnHeaders()}
                </MainTableHead>
                {adminDeliveryRules && (
                  <TableBody>
                    {adminDeliveryRules?.map((rule, index) => (
                      <ReturnRow
                        key={rule?.id}
                        row={rule}
                        index={index}
                        setCreateFeeDialog={setCreateFeeDialog}
                        handleShippingId={handleShippingId}
                        refetch={refetch}
                      />
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableComponent>
          )}
        </TableGrid>
      </TableContainerWrapper>
      <FooterGrid item container>
        <TablePagination
          total={totalCount}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </FooterGrid>
    </>
  );
};
ShippingFee.propTypes = {
  data: PropTypes.instanceOf(Object),
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  setCreateFeeDialog: PropTypes.func.isRequired,
  handleShippingId: PropTypes.func.isRequired,
};

ShippingFee.defaultProps = {
  data: {},
  loading: false,
};

export default ShippingFee;
