import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Box
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import CustomSelectWithSearch from '../../../customComponents/customSelectWithSearch';
import {
  CDialog, DialogTitleText, CircularProgressLoader, TextFieldBox, CTextField,
  GridWrapper, CloseIcon, FieldHeader
} from './createFeeDialog.styles';
import ConfirmDialog from '../confirmDialog';
import SuccessDialog from '../successDialog';
import currencyFormatter from '../../../shared/currencyFormatter';
import { CREATE_DELIVERY_FEE_MUTATION, UPDATE_DELIVERY_FEE_MUTATION } from '../../../../mutations/admin';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateFeeDialog = ({
  open, close, isEdit, setEditFeeDialog, category, shippingId, refetch, setShippingId
}) => {
  const initialState = {
    shippingFrom: '',
    shippingTo: '',
    quantityRange: '',
    deleiveryFee: 0,
    isDefault: false
  };

  const formatCurrency = (amount) => `₦${currencyFormatter(amount)}`;

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [createDeliveryRule] = useMutation(CREATE_DELIVERY_FEE_MUTATION);
  const [updateDeliveryRule] = useMutation(UPDATE_DELIVERY_FEE_MUTATION);
  const [isFocused, setIsFocused] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const {
    quantityRange, deleiveryFee, shippingTo, shippingFrom, isDefault
  } = state;

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  // // Update `isDefault` when `shippingTo` changes
  useEffect(() => {
    if (shippingTo === 'Default') {
      setState((prevState) => ({
        ...prevState,
        isDefault: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isDefault: false,
      }));
    }
  }, [shippingTo]);

  const availLocations = [
    'Default', 'Abia State', 'Adamawa State', 'Akwa Ibom State', 'Anambra State', 'Bauchi State', 'Bayelsa State', 'Benue State', 'Borno State', 'Cross River State', 'Delta State', 'Ebonyi State', 'Edo State',
    'Ekiti State', 'Enugu State', 'Abuja (FCT)', 'Gombe State', 'Imo State', 'Jigawa State', 'Kaduna State', 'Kano State', 'Katsina State', 'Kebbi State', 'Kogi State', 'Kwara State', 'Lagos State',
    'Nasarawa State', 'Niger State', 'Ogun State', 'Ondo State', 'Osun State', 'Oyo State', 'Plateau State', 'Rivers State', 'Sokoto State', 'Taraba State', 'Yobe State', 'Zamfara State'
  ];

  const mpSignUpLocations = [
    'Abia State', 'Adamawa State', 'Akwa Ibom State', 'Anambra State', 'Bauchi State', 'Bayelsa State', 'Benue State', 'Borno State', 'Cross River State', 'Delta State', 'Ebonyi State', 'Edo State',
    'Ekiti State', 'Enugu State', 'Abuja (FCT)', 'Gombe State', 'Imo State', 'Jigawa State', 'Kaduna State', 'Kano State', 'Katsina State', 'Kebbi State', 'Kogi State', 'Kwara State', 'Lagos State',
    'Nasarawa State', 'Niger State', 'Ogun State', 'Ondo State', 'Osun State', 'Oyo State', 'Plateau State', 'Rivers State', 'Sokoto State', 'Taraba State', 'Yobe State', 'Zamfara State'
  ];
  const range = ['0-50', '51-150', '151+'];

  const toField = [
    {
      name: 'shippingTo', label: 'Shipping To', options: availLocations, secured: false,
      show: true, onChange: handleSelectChange, placeholder: 'Select', required: true,
      val: shippingTo
    }
  ];

  const fromField = [
    {
      name: 'shippingFrom', label: 'Shipping From', options: mpSignUpLocations, secured: false,
      show: true, onChange: handleSelectChange, placeholder: 'Select', required: true,
      val: shippingFrom
    }
  ];

  const qtyRange = [
    {
      name: 'quantityRange', label: 'Quantity Range', options: range, secured: false,
      show: true, onChange: handleSelectChange, placeholder: 'Select', required: true,
      val: quantityRange
    }
  ];

  const handleFocus = () => {
    setIsFocused(true); // Set focus state to true
  };

  const handleBlur = () => {
    setIsFocused(false); // Set focus state to false
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText,
      secured, placeholder, onChange, val, isTooltip, tooltipDesc
    } = field;
    const value = state[fieldName];
    if (['shippingTo', 'shippingFrom', 'quantityRange'].includes(fieldName)) {
      return (
        <CustomSelectWithSearch
          key={fieldName}
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
          isTooltip={isTooltip}
          tooltipDesc={tooltipDesc}
        />
      );
    }
    return null;
  };

  const textFieldHeader = [
    {
      name: 'deleiveryFee', type: 'number',
      label: 'Deleivery Fee', placeholder: 'Enter Deleivery Fee'
    },
  ];

  useEffect(() => {
    if (shippingId && isEdit) {
      setState((prevState) => ({
        ...prevState,
        shippingFrom: shippingId?.shipFrom,
        shippingTo: shippingId?.shipTo,
        quantityRange: shippingId?.quantityMin === 151 ? `${shippingId?.quantityMin} +` : `${shippingId?.quantityMin} - ${shippingId?.quantityMax}`,
        deleiveryFee: Number(shippingId?.fee),
        isDefault: false
      }));
    }
  }, [shippingId, isEdit]);

  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  const toggleConfirmation = () => {
    setOpenDialog(!openDialog);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // If the field is a currency field, clean the value
    if (name === 'deliveryFee') {
      const numericValue = parseFloat(value.replace(/[^0-9.]/g, '')); // Remove non-numeric characters
      if (!Number.isNaN(numericValue)) { // Ensure the value is a valid number
        const positiveValue = Math.abs(numericValue); // Convert to positive
        setState({ ...state, [name]: positiveValue });
      }
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault(); // Block the minus key
    }
  };

  const handleCreate = () => {
    if (shippingTo === '' || shippingFrom === '' || quantityRange === '') {
      const emptyFields = [];
      if (shippingTo === '') emptyFields.push('Shipping To');
      if (shippingFrom === '') emptyFields.push('Shipping From');
      if (quantityRange === '') emptyFields.push('Quantity Range');
      const errorMessage = `${emptyFields.join(', ')} field${emptyFields.length > 1 ? 's' : ''} cannot be empty`;
      return toast.error(errorMessage);
    }
    setLoading(true);
    const rShippingTo = shippingTo === 'Abuja (FCT)' ? 'ABUJA' : shippingTo.replace('State', '').trim();
    const rShippingFrom = shippingFrom === 'Abuja (FCT)' ? 'ABUJA' : shippingFrom.replace('State', '').trim();

    const variables = {
      shippingTo: rShippingTo.toUpperCase(),
      shippingFrom: rShippingFrom.toUpperCase(),
      quantityRange,
      deleiveryFee: deleiveryFee || 0
    };
    createDeliveryRule({ variables })
      .then(({ data }) => {
        // const { message } = data?.createDeliveryRule || {};
        toast.success('Shipping Fee Created Successfully');
        setState(initialState);
        setSuccessDialog(true);
        refetch();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = () => {
    toggleConfirmation();
  };

  const handleSave = () => {
    if (isEdit) return handleEdit();
    return handleCreate();
  };

  const toggleSuccessModal = () => {
    handleSuccessDialog();
    setState(initialState);
    close();
  };

  const handleUpdate = () => {
    if (shippingTo === '' || shippingFrom === '' || quantityRange === '') {
      setOpenDialog(false);
      const emptyFields = [];
      if (shippingTo === '') emptyFields.push('Shipping To');
      if (shippingFrom === '') emptyFields.push('Shipping From');
      if (quantityRange === '') emptyFields.push('Quantity Range');
      const errorMessage = `${emptyFields.join(', ')} field${emptyFields.length > 1 ? 's' : ''} cannot be empty`;
      return toast.error(errorMessage);
    }
    const rShippingTo = shippingTo === 'Abuja (FCT)' ? 'ABUJA' : shippingTo.replace('State', '').trim();
    const rShippingFrom = shippingFrom === 'Abuja (FCT)' ? 'ABUJA' : shippingFrom.replace('State', '').trim();
    const variables = {
      id: Number(shippingId?.id), deleiveryFee,
      shippingTo: rShippingTo.toUpperCase(),
      shippingFrom: rShippingFrom.toUpperCase(),
      quantityRange,
    };
    updateDeliveryRule({ variables })
      .then(({ data }) => {
        const { message } = data?.updateDeliveryRule || {};
        toast.success(message);
        setState(initialState);
        handleSuccessDialog();
        setEditFeeDialog(false);
        setShippingId(null);
        refetch();
        toggleConfirmation();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const textField = (field) => {
    const {
      name: fieldName, type, placeholder
    } = field;
    const _value = state[fieldName];
    return (
      <TextFieldBox>
        <CTextField
          key={fieldName}
          onKeyDown={handleKeyDown}
          variant="outlined"
          value={isFocused ? _value : formatCurrency(_value)}
          size="large"
          name={fieldName}
          onChange={handleChange}
          type={isFocused ? 'number' : 'text'}
          placeholder={placeholder}
          fullWidth
          required
          className={`mpAdmin-uat-customerCategory-add-category-${fieldName}`}
          InputProps={{
            style: { height: '3.2rem', fontSize: '.75rem', borderRadius: '.5rem' }
          }}
          onFocus={handleFocus} // Handle focus
          onBlur={handleBlur} // Handle blur
        />
      </TextFieldBox>
    );
  };

  const handleCloseDialog = () => {
    setState(initialState);
    close();
    setEditFeeDialog(false);
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ marginTop: '1rem', padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              {isEdit ? 'Edit Fee' : 'Add Fee'}
            </DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Box>
          <Box>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={2}>
                {/* From Fields */}
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    {fromField
                      .filter((item) => item.show)
                      .map((field) => (
                        <Grid item key={field?.name} xs={12}>
                          {returnTextField(field)}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>

                {/* To Fields */}
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    {toField
                      .filter((item) => item.show)
                      .map((field) => (
                        <Grid item key={field?.name} xs={12}>
                          {returnTextField(field)}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              {qtyRange
                .filter((item) => item.show)
                .map((field) => (
                  <Grid item key={field?.name} lg={12} xs={12}>
                    {returnTextField(field)}
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {textFieldHeader.map((fields) => (
            <Box key={fields}>
              <FieldHeader>
                {fields.label}
              </FieldHeader>
              {textField(fields)}
            </Box>
          ))}
        </Box>

        <DialogActions
          sx={{
            '&.MuiDialogActions-root': {
              paddingTop: '0 !important', // Use !important to ensure it overrides
            },
          }}
        >
          <CustomButton
            type="tertiary"
            style={{
              width: '48%', height: '3.2rem', marginRight: '1.2rem',
              fontWeight: '700', fontSize: '.875rem'
            }}
            onClick={handleCloseDialog}
            className="mpAdmin-uat-shippingFee-add-fee-cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton
            style={{
              width: '48%', height: '3.2rem',
              fontWeight: '700', fontSize: '.875rem'
            }}
            onClick={handleSave}
            className="mpAdmin-uat-shippingFee-add-fee-save"
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : (
              'Save'
            )}
          </CustomButton>
        </DialogActions>
      </GridWrapper>
      <SuccessDialog
        openConfirmation={successDialog}
        handleClose={toggleSuccessModal}
        title="Success"
        discreption={isEdit ? 'You have successfully updated a fee!' : 'You have successfully added a new fee!'}
      />
      <ConfirmDialog
        openConfirmation={openDialog}
        handleClose={toggleConfirmation}
        title="Update Fee"
        discreption="Are you sure you want to update this fee?"
        buttonTitle="Yes, Update"
        handleConfirm={handleUpdate}
      />
    </CDialog>
  );
};

CreateFeeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  setEditFeeDialog: PropTypes.func.isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  shippingId: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  setShippingId: PropTypes.func.isRequired,
};

export default CreateFeeDialog;
