import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {
  PaymentSummaryGrandTotalRow, PaymentSummaryRow, PaymentSummaryRowBoldTitle, PayNowButton,
  PaymentSummaryRowTitle, PaymentSummaryCardHeader, PaymentSummaryCardHeaderTitle, ContinueButton,
  TipTool, TooltipWrapper, NoteWrapper, NoteTitle, NoteDesc, Note
} from './paymentSummary.styles';
import currencyFormatter from '../../shared/currencyFormatter';
import ServiceFeePopper from './serviceFeePopper';
import PlaceOrderAction from './placeOrder';
import { JSONParse } from '../../../utils/json';

const PaymentSummary = ({
  cart, orderTotal, serviceFee, deliveryFee, updateLoading, grandTotal,
  placeLoading, setOpenPriceDisclaimer, openConfirmUnavailable
}) => {
  const [action, setAction] = useState(null);

  const navigate = useNavigate();

  const unavailableProducts = cart.filter((prod) => {
    const meta = JSONParse(prod.product.meta);
    return meta.product_variant === 'NOT_AVAILABLE';
  });

  const handleOpenTip = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const priceDisclaimerHandler = () => {
    if (unavailableProducts.length > 0) return openConfirmUnavailable(true);
    setOpenPriceDisclaimer(true);
  };

  const disabled = placeLoading || updateLoading || !cart.length;

  return (
    <>
      <PaymentSummaryCardHeader>
        <PaymentSummaryCardHeaderTitle>Payment Summary</PaymentSummaryCardHeaderTitle>
      </PaymentSummaryCardHeader>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Order Total
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(orderTotal)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Service Fee
          <TipTool onClick={(event) => handleOpenTip(event)} />
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(serviceFee)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryGrandTotalRow>
        <PaymentSummaryRowTitle>
          Delivery Fee
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(deliveryFee)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryGrandTotalRow>
      {deliveryFee > 0 ? (
        <NoteWrapper>
          <Note>
            <TooltipWrapper>
              <TipTool />
            </TooltipWrapper>
            Quick Note
          </Note>
          <NoteTitle>
            Why am I seeing a delivery fee at checkout?
          </NoteTitle>
          <NoteDesc>
            While many orders qualify for free delivery, if your cart contains products tagged
            <b> delivery fee applies</b>
            , a delivery fee will be added based on the product&apos;s location
          </NoteDesc>
        </NoteWrapper>
      ) : '' }
      <Hidden mdDown>
        <PaymentSummaryGrandTotalRow>
          <PaymentSummaryRowBoldTitle
            style={{ color: '#000' }}
          >
            Grand Total
          </PaymentSummaryRowBoldTitle>
          <PaymentSummaryRowBoldTitle style={{ color: '#000' }}>
            {`₦ ${currencyFormatter(grandTotal)}`}
          </PaymentSummaryRowBoldTitle>
        </PaymentSummaryGrandTotalRow>

        <PayNowButton
          fullWidth
          onClick={() => priceDisclaimerHandler()}
          disabled={placeLoading || updateLoading || !cart.length}
          className="mpAdmin-uat-cartPage-placeOrder"
        >
          Place Order
        </PayNowButton>
        <ContinueButton
          fullWidth
          onClick={() => navigate('/new-order')}
          className="mpAdmin-uat-cartPage-continueShopping"
        >
          Continue Shopping
        </ContinueButton>
      </Hidden>
      <ServiceFeePopper action={action} handleOpenTip={handleOpenTip} />

      <Hidden mdUp>
        <PlaceOrderAction placeOrder={priceDisclaimerHandler} total={grandTotal} disabled={disabled} />
      </Hidden>
    </>
  );
};

PaymentSummary.propTypes = {
  cart: PropTypes.instanceOf(Array),
  updateLoading: PropTypes.bool.isRequired,
  orderTotal: PropTypes.number,
  serviceFee: PropTypes.number,
  deliveryFee: PropTypes.number,
  grandTotal: PropTypes.number,
  placeLoading: PropTypes.bool.isRequired,
  setOpenPriceDisclaimer: PropTypes.bool.isRequired,
  openConfirmUnavailable: PropTypes.func.isRequired
};

PaymentSummary.defaultProps = {
  cart: [],
  orderTotal: 0,
  serviceFee: 0,
  deliveryFee: 0,
  grandTotal: 0,
};

export default PaymentSummary;
