import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Grid, TableBody
} from '@mui/material';
import moment from 'moment';
import currencyFormatter from '../../../../shared/currencyFormatter';
import {
  PageTitleContainer, TableHeader, MainTableRow,
} from '../../../../cart/cart.styles';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, PageTitleText, PageTitleSubText,
  SubTotalText, SubTotalPrice, SubTotalBox, TableWrapper, TableComponent, Header,
  BackText, OrderDetailCard, OrderDetailWrapper, ImgContent, CardLogo, OrderDetailInfoWrapper, TitleText,
  SubText, SupplierDetailCardHeader, SupplierDetailCardTitle, OrderDeliveryDetailInfoWrapperRow, OrderDeliveryInfoLabel, OrderDeliveryInfoText, CartGridContainer,
  OrderStatus, KeyboardArrowLeft, DeliveryColor, BackArrowIconContainer, CartGridContainerHeader, GrandTotalBox, GrandTotaltext,
  PackagesPaper, CustomPaper
} from './styles';
import { GET_ORDER_DETAILS_ORDER, ORDER_PACKAGES_DETAIL } from '../../../../../queries/orders';
import MainContent from '../../../../customComponents/mainContent';
import SupplierOrderDetailProductItem from './product-item';
import ActionButtonsPopper from './actionPopper';

import { parseJson } from '../../../../affiliates/utils';
import TabsButtons from './ordersTabsButtons/tabsButtons';
import NewOrdersLoader from '../../../../customComponents/loaders/newOrdersLoader';

const initialState = {
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  totalPromo: 0,
  showCancelOrderDialog: false,
  editNote: false,
  note: '',
  orderId: null,
  supplierPaymentTerm: null,
  orderMeta: null,
  showPaymentDetails: false,
  orderStatus: null,
  deliveryId: null,
  business: {},
  products: [],
  deliveryFee: 0
};
const OrderDetailsContainer = () => {
  const [state, setState] = useState(initialState);
  const [active, setActive] = useState(0);
  const [packageList, setPackageList] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const itemNo = location?.state;

  const { isBuyer, isSeller, businessId } = itemNo || {};

  const calculateSubtotal = () => {
    const { products } = state;
    return products.reduce((total, product) => total + (product.price || 0), 0);
  };

  const { loading, data } = useQuery(GET_ORDER_DETAILS_ORDER, {
    variables: { id: +params.id, businessId: +businessId },
    fetchPolicy: 'no-cache',
    skip: isBuyer,
  });

  const { loading: packageLoading, data: packageData } = useQuery(ORDER_PACKAGES_DETAIL, {
    variables: { orderId: +params.id, adminPage: true },
    fetchPolicy: 'no-cache',
    skip: isSeller,
  });

  useEffect(() => {
    if (data && data?.businessOrder) {
      const {
        businessOrder: {
          id: orderId,
          refId,
          total,
          business,
          status,
          meta: deliveryMeta,
          order: {
            dateCreated,
            paymentSummary,
            meta,
            business: buyerInfo,
            id: parentOrderId
          }
        }
      } = data;

      const datePlaced = moment(dateCreated).format('MMMM DD, YYYY [at] h:mma');
      const {
        sellerContact, location: sellerLocation, name: sellersName, businessuserSet
      } = business;
      const { buyerContact, name: buyersName } = buyerInfo;
      const sellerDeliveryFee = parseJson(deliveryMeta);
      const parseSummary = parseJson(paymentSummary);
      const parseMeta = parseJson(meta);
      const serviceCharge = parseSummary?.service_charge ?? 0;
      const deliveryFee = sellerDeliveryFee?.delivery_fee?.amount ?? 0;

      setState((s) => ({
        ...s,
        orderId,
        refId,
        sellerContact,
        dateCreated: datePlaced,
        business,
        serviceCharge,
        paymentSummary: parseSummary,
        products: data?.orderProducts || [],
        status,
        total,
        sellerLocation,
        parseMeta,
        buyerContact,
        buyersName,
        sellersName,
        parentOrderId,
        userId: businessuserSet[0]?.user?.id,
        deliveryFee
      }));
    }
  }, [data]);

  useEffect(() => {
    if (packageData && packageData?.order) {
      const {
        order: {
          id: orderId,
          datePlaced: dateCreated,
          businessorderSet,
          business,
          paymentSummary,
          meta,
          status
        }
      } = packageData;
      const datePlaced = moment(dateCreated).format('MMMM DD, YYYY [at] h:mma');
      const { buyerContact, name } = business;
      const updatedRefId = `${params?.id}-${businessorderSet[0]?.id}`;
      const { business: { location: sLocation, name: sName, sellerContact: sContact } } = businessorderSet[0];
      const { city, storeAddress, phoneNumber } = sContact;
      const parseMeta = parseJson(meta);
      const { delivery_address: deliveryAddress } = parseMeta;
      const parseSummary = parseJson(paymentSummary);
      const { total_amount: totalAmount } = parseSummary;
      const updatedSellerContact = { city, storeAddress, phoneNumber };

      const businessOrder = businessorderSet.map((pkg) => {
        const parsedMeta = parseJson(pkg.meta);
        return {
          ...pkg,
          deliveryFee: parsedMeta?.delivery_fee?.amount || 0,
        };
      });

      setState((s) => ({
        ...s,
        orderId,
        buyerContact,
        dateCreated: datePlaced,
        business,
        businessorderSet,
        products: businessorderSet[0]?.orderproductSet || [],
        deliveryFee: businessOrder[0]?.deliveryFee,
        totalAmount,
        refId: updatedRefId,
        buyersName: name,
        status,
        deliveryAddress,
        sellerContact: updatedSellerContact,
        sellersName: sName,
        sellerLocation: sLocation
      }));

      setActive(0);
      setPackageList(
        businessorderSet.map((_, index) => `Package ${index + 1}`)
      );
    }
  }, [packageData]);

  const {
    sellerContact, dateCreated, products, parseMeta, status, sellerLocation, buyersName, buyerContact, refId, sellersName, deliveryFee,
    deliveryAddress
  } = state;

  const subtotal = calculateSubtotal();
  const totalAmount = +subtotal + +deliveryFee;

  const {
    delivery_address: sDeliveryAddress
  } = parseMeta || {};

  const getColorGrid = (color, bkg, name) => (
    <OrderStatus item container className={`mpAdmin-uat-ordersPage-${name}`}>
      <DeliveryColor color={bkg} />
      {name}
    </OrderStatus>
  );

  const handleChangePackage = (pkgIndex) => {
    const selectedPackage = state.businessorderSet?.[pkgIndex];
    const { meta, business: { location: sLocation, name: sName, sellerContact: sContact } } = selectedPackage;
    const { city, storeAddress, phoneNumber } = sContact;
    const updatedSellerContact = { city, storeAddress, phoneNumber };
    const parsedMeta = parseJson(meta);
    const packageDeliveryFee = parsedMeta?.delivery_fee?.amount ?? 0;

    setActive(pkgIndex);
    if (selectedPackage) {
      const updatedRefId = `${params?.id}-${selectedPackage?.id}`;
      setState((s) => ({
        ...s,
        products: selectedPackage.orderproductSet || [],
        refId: updatedRefId,
        sellerContact: updatedSellerContact,
        sellersName: sName,
        sellerLocation: sLocation,
        deliveryFee: packageDeliveryFee
      }));
    }
  };

  const getStatus = () => {
    switch (status) {
      case 'ORDER_PLACED':
        return getColorGrid('#07DE10', '#00B588', 'Order placed');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#00BFFF', 'Dispatched');
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return getColorGrid('#606060', '#00BFFF', 'Ready for Dispatch');
      case 'ORDER_REJECTED':
        return getColorGrid('#606060', '#FF0F0F', 'Rejected');
      case 'ORDER_SORTED':
        return getColorGrid('#606060', '#F29053', 'Order Sorted');
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#F29053', 'Order sent');
      case 'ORDER_CLOSED':
        return getColorGrid('#07DE10', '#F29053', 'Completed');
      case 'ORDER_AWAITING_APPROVAL':
        return getColorGrid('#606060', '#F29053', `${isBuyer ? 'Order Placed' : 'Awaiting Approval'}`);
      case 'CLOSED':
        return getColorGrid('#07DE10', '#00B588', 'Completed');
      default:
        return getColorGrid('#606060', '#00B588', `${isBuyer ? 'Order Approved' : 'Order Received'}`);
    }
  };

  return (
    <MainContent>
      { (loading || packageLoading) ? <NewOrdersLoader column={5} row={4} />
        : (
          <CartGridContainer>
            <CartGridContainerHeader container>
              <Grid xs={12}>
                <BackArrowIconContainer onClick={() => navigate(-1)}>
                  <KeyboardArrowLeft />
                  <BackText>Back</BackText>
                </BackArrowIconContainer>
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <PageTitleContainer>
                  <PageTitleText>
                    Order ID &nbsp;
                    {refId}
                  </PageTitleText>
                  <PageTitleSubText>
                    Order Package &nbsp;
                    {isBuyer && active + 1}
                  </PageTitleSubText>
                </PageTitleContainer>
              </Grid>
              {itemNo?.isSeller ? (
                <Grid>
                  <ActionButtonsPopper state={state} businessId={+itemNo.businessId} />
                </Grid>
              ) : ''}
            </CartGridContainerHeader>

            {isBuyer && (
              <CustomPaper>
                <PackagesPaper elevation={0}>
                  <TabsButtons
                    active={active}
                    setActive={setActive}
                    changePackage={handleChangePackage}
                    packages={packageList}
                  />
                </PackagesPaper>
              </CustomPaper>
            )}

            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item container lg={4}>
                <OrderDetailCard>
                  <OrderDetailWrapper container>
                    <ImgContent>
                      <CardLogo />
                    </ImgContent>
                  </OrderDetailWrapper>
                  <Grid item>{getStatus()}</Grid>
                  <OrderDetailInfoWrapper>
                    <TitleText>
                      Order ID: &nbsp; &nbsp;
                      <SubText>
                        {`#${refId}`}
                      </SubText>
                    </TitleText>
                    <TitleText>
                      Date Placed: &nbsp; &nbsp;
                      <SubText>
                        {`${dateCreated || 'May 15, 2024 at 12:30 pm'}`}
                      </SubText>
                    </TitleText>
                  </OrderDetailInfoWrapper>
                </OrderDetailCard>
              </Grid>

              <Grid item container lg={4}>
                <SupplierDetailCardWrapper>
                  <SupplierDetailCard elevation={0}>
                    <>
                      <SupplierDetailCardHeader>
                        <SupplierDetailCardTitle>Delivery Location</SupplierDetailCardTitle>
                      </SupplierDetailCardHeader>
                      <OrderDeliveryDetailInfoWrapperRow>
                        <Grid>
                          <OrderDeliveryInfoLabel>Buyer’s Name</OrderDeliveryInfoLabel>
                          <OrderDeliveryInfoText>{ buyersName || 'N/A' }</OrderDeliveryInfoText>
                        </Grid>
                        <Grid>
                          <OrderDeliveryInfoLabel>Mobile</OrderDeliveryInfoLabel>
                          <OrderDeliveryInfoText>{ buyerContact && buyerContact.phoneNumber ? buyerContact.phoneNumber : 'N/A' }</OrderDeliveryInfoText>
                        </Grid>
                        <Grid>
                          <OrderDeliveryInfoLabel>Email Address</OrderDeliveryInfoLabel>
                          <OrderDeliveryInfoText>{ buyerContact && buyerContact.emailAddress ? buyerContact.emailAddress : 'N/A' }</OrderDeliveryInfoText>
                        </Grid>
                        <Grid>
                          <OrderDeliveryInfoLabel>Location</OrderDeliveryInfoLabel>
                          <OrderDeliveryInfoText>
                            {(deliveryAddress && isBuyer)
                              ? deliveryAddress
                              : sDeliveryAddress || 'N/A'}
                          </OrderDeliveryInfoText>
                        </Grid>
                      </OrderDeliveryDetailInfoWrapperRow>
                    </>
                  </SupplierDetailCard>
                </SupplierDetailCardWrapper>
              </Grid>

              <Grid item container lg={4}>
                <SupplierDetailCardWrapper>
                  <SupplierDetailCard elevation={0}>
                    <>
                      <SupplierDetailCardHeader>
                        <SupplierDetailCardTitle>Seller’s Details</SupplierDetailCardTitle>
                      </SupplierDetailCardHeader>
                      <OrderDeliveryDetailInfoWrapperRow>
                        <Grid>
                          <OrderDeliveryInfoLabel>Seller’s Name</OrderDeliveryInfoLabel>
                          <OrderDeliveryInfoText>{ sellerContact && sellersName ? sellersName : 'N/A' }</OrderDeliveryInfoText>
                        </Grid>
                        <Grid>
                          <OrderDeliveryInfoLabel>Mobile</OrderDeliveryInfoLabel>
                          <OrderDeliveryInfoText>{ sellerContact && sellerContact.phoneNumber ? sellerContact.phoneNumber : 'N/A' }</OrderDeliveryInfoText>
                        </Grid>
                        <Grid>
                          <OrderDeliveryInfoLabel>Location</OrderDeliveryInfoLabel>
                          <OrderDeliveryInfoText>{ sellerContact ? `${sellerContact.storeAddress}, ${sellerContact.city}, ${sellerLocation}` : 'N/A' }</OrderDeliveryInfoText>
                        </Grid>
                      </OrderDeliveryDetailInfoWrapperRow>
                    </>
                  </SupplierDetailCard>
                </SupplierDetailCardWrapper>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item container lg={12}>
                <SupplierDetailCardWrapper elevation={2}>
                  <TableWrapper>
                    <TableComponent aria-label="affiliates table">
                      <TableHeader>
                        <MainTableRow>
                          <Header style={{ paddingLeft: '1rem' }}> Product name</Header>
                          <Header> Qty Received</Header>
                          <Header> Qty Ordered</Header>
                          <Header> Unit Cost</Header>
                          <Header>Price</Header>
                        </MainTableRow>
                      </TableHeader>
                      <TableBody>
                        {products.map((productItem) => (
                          <SupplierOrderDetailProductItem
                            key={productItem}
                            product={productItem}
                          />
                        ))}
                      </TableBody>
                    </TableComponent>
                  </TableWrapper>
                  <SubTotalBox container>
                    <Grid item xs={6}>
                      <SubTotalText>
                        Subtotal
                      </SubTotalText>
                    </Grid>
                    <Grid item xs={6}>
                      <SubTotalPrice>
                        {`₦${currencyFormatter(subtotal)}`}
                      </SubTotalPrice>
                    </Grid>
                  </SubTotalBox>
                  <SubTotalBox container>
                    <Grid item xs={6}>
                      <SubTotalText>
                        Delivery Fee
                      </SubTotalText>
                    </Grid>
                    <Grid item xs={6}>
                      <SubTotalPrice>
                        {`₦${currencyFormatter(Number(deliveryFee))}`}
                      </SubTotalPrice>
                    </Grid>
                  </SubTotalBox>
                  <GrandTotalBox container>
                    <Grid item xs={6}>
                      <GrandTotaltext>
                        {isBuyer ? 'Package Total' : 'Grand Total'}
                      </GrandTotaltext>
                    </Grid>
                    <Grid item xs={6}>
                      <SubTotalPrice style={{ fontWeight: 'bold' }}>
                        {`₦${currencyFormatter(Number(totalAmount))}`}
                      </SubTotalPrice>
                    </Grid>
                  </GrandTotalBox>
                </SupplierDetailCardWrapper>
              </Grid>
            </Grid>
          </CartGridContainer>
        )}
    </MainContent>
  );
};

export default OrderDetailsContainer;
