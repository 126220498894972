import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatusesGrid = styled(Grid)`
  padding: .5rem 0;
  align-items: flex-end;
  display: flex;
  border-bottom: 2px solid #EEEEEE;
  width: 100%;
  padding-bottom: 0;
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border-bottom: ${({ active }) => (active ? '4px solid #235A91' : '2px solid transparent')};
  padding-bottom: .5rem;
  margin-bottom: -2px;
`;

export const Typo = styled(Typography)`
  font-size: 13px;
  font-weight: 600;
  padding: 10px 15px;
  color: ${({ active }) => (active ? '#303030' : '#C0C0C1')};
  display: flex;
  align-items: center;
`;

export const NoteBox = styled(Box)`
  border-radius: 6px;
  padding: 10px;
  background: #f3f9ff;
  margin: 1rem 0;
  display: flex;
  gap: .7rem;
`;

export const NoteTypo = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
  color: #606060;
  margin-top: 4px;
`;

export const SubNoteTypo = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  color: #606060;
  margin-top: 4px;
`;

export const NoteImage = styled('img')`
`;
