import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomNewTextField from '../../../shared/CustomNewTextField';
import currencyFormatter from '../../../shared/currencyFormatter';
import ActionPopper from './actionPopper';
import {
  BodyCell, MainTableRow, MenuItemStyled
} from './returnRow.styles';
import { Dots } from '../../../../assets/svgs';
import ConfirmDialog from '../confirmDialog';
import SuccessDialog from '../successDialog';
import AppToast from '../../../shared/appToast';
import { CHANGE_DELIVERY_FEE_MUTATION, DELETE_DELIVERY_FEE_MUTATION } from '../../../../mutations/admin';

const ReturnRow = ({
  row, index, setCreateFeeDialog, handleShippingId, refetch
}) => {
  const {
    id, isActive, fee, shipFrom, shipTo, quantityMin, quantityMax
  } = row;
  const formatCurrency = (amount) => `₦${currencyFormatter(amount)}`;
  const [action, setAction] = useState(null);
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [toastMessage, setToastMessage] = useState({
    title: '',
    description: ''
  });
  const status1 = [
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Inactive', value: 'IN_ACTIVE' },
  ];

  const [changeDeliveryRuleStatus] = useMutation(CHANGE_DELIVERY_FEE_MUTATION);
  const [deleteDeliveryRule] = useMutation(DELETE_DELIVERY_FEE_MUTATION);

  const getStatusNam = (stat) => {
    switch (stat) {
      case true:
        return { [status1[0].name]: status1[0].value }; // { Active: 'ACTIVE' }
      case false:
        return { [status1[1].name]: status1[1].value }; // { Inactive: 'IN_ACTIVE' }
      default:
        return { [status1[1].name]: status1[1].value };
    }
  };

  const getStatusName = (stat) => {
    switch (stat) {
      case true:
        return status1[0].name; // 'Active'
      case false:
        return status1[1].name; // 'Inactive'
      default:
        return status1[1].name;
    }
  };

  const [state, setState] = useState(getStatusNam(isActive));

  useEffect(() => {
    setState(getStatusNam(isActive));
  }, [row]);

  const formatState = (item) => item.trim().replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

  const erpCells = () => [
    shipFrom.includes('ABUJA') ? formatState(shipFrom) : `${formatState(shipFrom)} State`,
    shipTo.includes('ABUJA') || shipTo === 'DEFAULT' ? formatState(shipTo) : `${formatState(shipTo)} State`,
    quantityMin === 151 ? `${quantityMin} +` : `${quantityMin} - ${quantityMax}`,
    formatCurrency(fee),
  ];

  const textFields = [
    {
      name: getStatusName(isActive),
      label: '',
      options: status1
    },
  ];

  const handleStatusUpdate = () => {
    if (id) {
      const variables = {
        id: Number(id),
        status: !isActive
      };
      changeDeliveryRuleStatus({ variables })
        .then(({ data }) => {
          const { message } = data?.changeDeliveryRuleStatus || {};
          toast.success(`Delivery rule ${isActive === true ? 'deactivated' : 'activated'} successfully`);
          refetch();
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
    handleStatusUpdate();
  };
  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const toggleConfirmation = () => {
    setOpen(!open);
  };

  // handle sending the shipping ID
  const handleEditShippingFee = (rowData) => {
    handleShippingId(rowData);
  };

  const handleAction = (event, type, rowData) => {
    if (type === 'edit') {
      setCreateFeeDialog(true);
      handleEditShippingFee(rowData);
    } else if (type === 'delete') {
      setSelectedId(rowData);
      toggleConfirmation();
    }
    handleOptions(event);
  };

  const returnCell = (val) => val;

  const renderBodyCells = () => erpCells()?.map((cell) => (
    <BodyCell key={cell} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  const handleDelete = () => {
    if (id) {
      const variables = {
        id: Number(id)
      };
      deleteDeliveryRule({ variables })
        .then(({ data }) => {
          const { message } = data?.deleteDeliveryRule || {};
          // toast.success(message);
          refetch();
          toggleConfirmation();
          setSuccessModal(true);
          setToastMessage({
            title: 'Shipping Fee Removed Successfully!',
            description: 'Your shipping fee has been successfully removed!'
          });
          setOpenToast(true);
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  return (
    <>
      <MainTableRow item container style={index % 2 ? { backgroundColor: '#F3F9FF' } : { backgroundColor: '#FFF' }}>
        {renderBodyCells()}
        <BodyCell className="mpAdmin-uat-shippingfee-status-button">
          {
            textFields.map((field) => {
              const { name, label, options } = field;
              return (
                <CustomNewTextField
                  key={name}
                  field={{ name, label }}
                  state={state}
                  select
                  handleChange={handleFilterChange}
                  marginLeft="4.5rem"
                  style={{ width: '9rem' }}
                >
                  {options?.map(({ name: fName, value }) => (
                    <MenuItemStyled
                      key={value}
                      value={value}
                    >
                      {fName}
                    </MenuItemStyled>
                  ))}

                </CustomNewTextField>
              );
            })
          }
        </BodyCell>
        <BodyCell className="mpAdmin-uat-shippingfee-action-button">
          <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
        </BodyCell>
        <ActionPopper
          action={action}
          handleAction={handleAction}
          row={row}
          handleClose={handleCloseModal}
        />
      </MainTableRow>
      <ConfirmDialog
        openConfirmation={open}
        handleClose={toggleConfirmation}
        title="Remove Fee"
        discreption="Are you sure you want to remove this fee?"
        buttonTitle="Yes, Remove"
        handleConfirm={handleDelete}
      />
      {/* <SuccessDialog
        openConfirmation={successModal}
        handleClose={toggleSuccessModal}
        title="Shipping Fee Removed Successfully!"
        discreption="Your shipping fee has been successfully removed!"
      /> */}
      <AppToast openToast={openToast} setOpenToast={setOpenToast} toastMessage={toastMessage} isWarning={false} />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  setCreateFeeDialog: PropTypes.func.isRequired,
  handleShippingId: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
